<template>
  <div class="dash_vacs_mainpage canna_pageFlexZone" style="width: 100%;height: calc(100% - 0px);overflow: hidden;">

    <pageHeader
      :showSubtitle="true"
      :showTitleAltZone="false"
      :showTlbZone="false"
      :cls_title_altZone="'pruebaAltZonewwww'"
      class="canna_vacantesPageHeader"
    >
      <slot>
        <div class="" style="width: calc(100% - 10px);position: relative;">
          <div>
            Vacantes
          </div>
        </div>
      </slot>
      <template v-slot:subtitle>
        <div>
          Aquí podrás encontrar las vacantes laborales que tenemos activas
        </div>
      </template>
    </pageHeader>

    <div id="dash_docs_header" class="noselect dash_docs_header">
        <div>
          <template v-if="numVacantes >= 1 && current_profile.status >= estatusx_USR.REDES_SOCIALES">
            {{numVacantes}} Empleo{{(numVacantes>1)?'s' : ''}} vacante{{(numVacantes>1)?'s' : ''}}
          </template>
          <template v-else>
            Sin vacantes
          </template>
        </div>
    </div>

    <div id="dash_vacs_contentPage" class="dash_vacs_contentPage">

      <q-no-ssr tag="div" id="dash_vacs_contents" class="dash_vacs_contents" v-if="zinitData===true">

          <!-- si no completa la info todavia -->
          <template v-if="(current_profile.status < estatusx_USR.REDES_SOCIALES )">

            <div>
              <div class="vacs_nodata_zone">
                <div class="title spacerRowTop48">¡Te falta algo!</div>
                <div class="text_subExplainer spacerRowTop4">
                  Para poder visualizar las vacantes laborales es necesario que termines de responder <br>
                  todos los datos del menú 
                  <span class="action_item vacantes_actionText" @click.stop="goToQuizPage">Información personal</span>
                </div>
                <div class="nodataImage spacerRowTop48" style="justify-content: center;display: flex;">
                  <cxBackground :bgType="'planes_nosearch'" :size="currentSizeNodata" />
                </div>
              </div>
            </div>

          </template>
          <template v-else-if="(current_profile.status >= estatusx_USR.REDES_SOCIALES && vacantesData.length<=0)">

            <div>
              <div class="vacs_nodata_zone">
                <div class="title spacerRowTop48">Por ahora no hay vacantes</div>
                <div class="text_subExplainer spacerRowTop4">
                  ¡Estamos completos! Por ahora no tenemos vacantes disponibles, 
                  <template v-if="(is_Mobile || $q.screen.lt.sm)"> </template><template v-else> <br> </template>
                  vuelve pronto a esta pantalla para saber si hay nuevas oportunidades.
                </div>
                <div class="nodataImage spacerRowTop48" style="justify-content: center;display: flex;">
                  <cxBackground :bgType="'planes_nosearch'" :size="currentSizeNodata" />
                </div>
              </div>
            </div>

          </template>
          <template v-else-if="(current_profile.status >= estatusx_USR.REDES_SOCIALES && vacantesData.length>0)">

            <div class="dash_docs_container">
                <div 
                  v-for="(docItem, index) in vacantesData" 
                  :key="'docitem_' + index + '_' + docItem.id" 
                  class="dash_docItem" 
                  :style="(index===(vacantesData.length-1)) ? 'margin-bottom: 30px;' : '' "
                  @click.stop="vacante_gotoDetail(docItem)"
                >
                  <!-- encabezado -->
                  <div class="noselect docHeader action_item" :class="{}">
                    <!-- icono de Vacante  -->
                    <div style="margin-right:24px">
                      <cxIcon 
                        iconType="vacante_Listitem"
                        class="xxx-demo zzz-prueba"
                        :cls="'canna_vacante_Icon_sample'"
                        size="40px"
                        :state="0"
                        :hasMouseOver="false"
                        :hasAction="false"
                        :allowDisabled="false"
                        :allowClick="false"
                      />
                    </div>

                    <div style="flex:1;">
                      <div class="doc_title"> {{docItem.nombre_vacante}} </div>
                      <template v-if="(is_Mobile|| $q.screen.lt.md)">
                        <div class="doc_subtitle spacerRowTop8"  style="font-weight: 500;" v-html="docItem.z_subtituloMobil"></div>
                      </template>
                      <template v-else>
                        <div class="doc_subtitle spacerRowTop8" style="font-weight: 500;" v-html="docItem.z_subtitulo"></div>
                      </template>

                      <template v-if="(is_Mobile|| $q.screen.lt.md) && docItem.postulado==1">
                        <div style="display: flex; margin-top:8px;">
                          <div style="background-color: #6FCF97;border-radius: 32px;display: flex;color: #fff;padding: 4px 16px; align-items: center;">
                            <div>
                              <q-icon name="check_circle" style="color: #fff; font-size: 1.4em;" />
                            </div>
                            <div style="font-size: 14px;font-weight: 400;margin-left: 10px;">
                              Te has postulado
                            </div>
                          </div>
                        </div>
                      </template>

                    </div>

                    <template v-if="(is_Mobile ==false || $q.screen.gt.sm) && docItem.postulado==1">
                      <div style="margin-right: 24px;">
                        <div style="background-color: #6FCF97;border-radius: 32px;display: flex;color: #fff;padding: 12px 16px; align-items: center;">
                          <div>
                            <q-icon name="check_circle" style="color: #fff; font-size: 1.4em;" />
                          </div>
                          <div style="font-size: 14px;font-weight: 400;margin-left: 10px;">
                            Te has postulado
                          </div>
                        </div>
                      </div>
                    </template>

                    <div>
                      <div class="action_item" style="width: 24px;height: 24px;display: flex;align-items: center;" @click.stop="vacante_gotoDetail(docItem)" >
                        <q-icon name="r_chevron_right" style="color: #6633FF; font-size: 1.8em;" />
                      </div>
                    </div>
                  </div>

                </div>
            </div>

          </template>

      </q-no-ssr>

    </div>

  </div>
</template>

<script>
import { requestPack } from './../../../boot/axios' // APP code 

import pageHeader from 'components/layout/headerPage'

import logOut_Mixin from './../../../mixins/requestMix.js'
import myplatform_Mixin from './../../../mixins/platformMix.js'
import helperBase_Mixin from './../../../mixins/helperBase.js'
import helperCats_Mixin from './../../../mixins/helperCatsMix.js'

import numeral from 'numeral'

export default {
  name:'vacantes_index',
  mixins: [
    helperBase_Mixin,
    logOut_Mixin,
    myplatform_Mixin,
    helperCats_Mixin
  ],
  components:{
    cxIcon: require('components/appIcons.vue').default,
    cxBackground: require('components/appBackgrounds').default,
    pageHeader
  },
  data () { 
    return {
      version: '1.0.1',
      debugEls: false,
      mynumeral: numeral, // Utilerias
      //-------------
      zinitData:false,
      zinitScreen: false,
      zloadingData: false,
      processingData: false,
      loading_Process: false, 
      currentSizeNodata:'300px',
      //------------
      vacantesData:[]
    }
  },
  computed: {
    numVacantes () {
      return this.vacantesData.length
    }, 
    estatusx_USR () { 
      return this.$cannaEstatus.usuario 
    },
    current_profile (){
      return this.getProfileData
    },
    currStatusPersona (){ 
      return this.getProfileData.status
    }
  },
  watch: {

  },
  methods: {
    ...requestPack,
    testFn () { 
      this.$cannaDebug('-- vacantes index -- test')
    },
    test () { 
      this.$cannaDebug('-- vacantes index -- test')
    },
    showLoadx ( value, type ){
      // type: 1 orbit -- 2 gears 
      var tpuse = (type===undefined || type===null || type==='') ? 2 : (type === 1 || type === 2) ? type : 2 
      this.$parent.showLoadr(tpuse , value )
    },
    goToQuizPage (){
      this.$cannaDebug('-- vacantes index -- go To Quiz Page')
      this.$parent.$parent.$parent.$parent.$parent.goto_PersonalPage()
    },
    async vacante_gotoDetail(docItem) { 
      this.$cannaDebug('-- vacantes index -- init Form Data')
      var myObject  = JSON.parse(JSON.stringify(docItem))
      this.$store.commit('myapp/setMainState',{ currentVacanteDetail: myObject })
      await this._waitRequestProc(200)
      var newRuta = '/dashboard/vacantes/' + myObject.id 
      setTimeout(() => {
        this.$router.push(newRuta)
      }, 150)
    },
    async initFormData (){ 
      this.$cannaDebug('-- vacantes index -- init Form Data')
      var test=11
      var mcModel = this.getModelRequest('profile_Vacantes')
      var reqRes = { action: [] }
      var reqErr = { action: 0 }
      var myFormConfig = this.createAxiosConfig ('get', mcModel, {'per-page': 'all', expand:'compensacionesPago,jornada,horario', sort:'-id' }, true, null)
      await this.doRequestByPromise(this.$apiV2, 'profDocs', reqRes.action, myFormConfig).then((resx) => {
        // console.log ('Action -- Carga de Docs -- GET ejecutado')
        var tmpresponseData = reqRes.action[reqRes.action.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.action += 1
        }
      })
      //-----------------------
      if (reqErr.action > 0) { // Si hay error de server, session o algo redireccionamos..!
        test = 55
        this.ex_handleErrorRequest (reqRes.action[0], reqErr.action)
        this.vacantesData = []
        return false 
      }
      // -----------------------
      var mVacData = reqRes.action[0].data.data
      var arrVacDataUSe = []
      if (mVacData.length>0){ 
        mVacData.forEach( (item , indexItem) => { 
          var x_sueldoBruto = 0 
          item._embedded.compensacionesPago.forEach( cpago => { 
            var costMens = parseFloat(cpago.costoMensual)
            cpago.z_totalMensual = '$ ' + this.mynumeral(costMens).format('0,0.00')
            x_sueldoBruto+= parseFloat(cpago.costoMensual)
          })
          item.z_sueldoTotal = this.mynumeral(x_sueldoBruto).format('0,0.00')
          var fechaStr = this.$moment(item.fecha_publicacion, 'YYYY-MM-DD HH:mm:ss').format('DD, MMM, YYYY').replace(/\./g,'')
          item.z_subtitulo = '' + item.ciudadNombre + ' | Publicada el <span style="text-transform: capitalize;">' + fechaStr + '</span>'
          item.z_subtituloMobil = '' + item.ciudadNombre + ' <br> Publicada el <span style="text-transform: capitalize;">' + fechaStr + '</span>'

          //Ajuste para verificar que la vacante tenga descripcion 
          if (('' + item.descripcion).trim()!==''){
            arrVacDataUSe.push(JSON.parse(JSON.stringify(item)))
          }
        })
      }
      this.vacantesData = JSON.parse(JSON.stringify(arrVacDataUSe))
      //vacantes:['v2','vacantes'] // ${secure}://${server}/index.php/api/v2/vacantes?per-page=20&expand=compensacionesPago,jornada,horario
      return true 
    }
  },
  beforeCreate () {
    //this.$cannaDebug('-- vacantes index -- beforeCreate')
  },
  created () {
    this.$cannaDebug('-- vacantes index -- created')
    this.zloadingData = true 
    this.zinitData = false
  },
  beforeMount () {
    // this.$cannaDebug('-- vacantes index --  beforeMount')
  },
  async mounted () {
    this.$cannaDebug('-- vacantes index -- mounted')
    this.zloadingData = true 
    this.showLoadx(true, 1)
    // if (this.$store.state.myapp.helperCatalog.length<=0){ 
    //   await this.helper_getAllCatalogs().then(respx =>{
    //     this.$cannaDebug('-- docsMainScreen -- get All Catalogs -- finished ')
    //   })
    //   await this._waitRequestProc(1500)
    // }

    // if (this.getHelperCat(15).dataLoaded===false){ 
    //   var testCatA = false 
    //   await this.helpermix_getCatalog (15, null).then( respx => { 
    //     this.$cannaDebug('-- psiPage_puestos --   categoria 15 videos finish ')
    //     testCatA = respx 
    //   })
    // }
    // await this._waitRequestProc(500)

    var respInitData = null 
    await this.initFormData().then( respx => {
      respInitData = respx 
    })

    await this._waitRequestProc(200)

    // this.$cannaDebug('-- docsMainScreen -- init Form Data Finished -- ' + respInitData)
    this.showLoadx(false, 1)
    this.zinitData = true 
    this.zloadingData = false
    setTimeout(() => {
      this.zinitScreen = true
    }, 500)
    return true 
  },
  beforeUpdate () {
    // this.$cannaDebug('-- vacantes index -- beforeUpdate')
  },
  updated () {
    // this.$cannaDebug('-- vacantes index -- updated')
  },
  async activated () {
    this.$cannaDebug('-- vacantes index -- activated')
    if (this.zinitScreen === true){
      this.zloadingData = true
      this.showLoadx(true, 1)

      var respInitData = null 
      await this.initFormData().then( respx => {
        respInitData = respx 
      })

      await this._waitRequestProc(200)
      
      this.zloadingData = false
      this.showLoadx(false, 1)
    }
  },
  deactivated () {
    // this.$cannaDebug('-- vacantes index -- deactivated')

  },
  beforeDestroy () {
    // this.$cannaDebug('-- vacantes index -- beforeDestroy')
  },
  destroyed () {
    // this.$cannaDebug('-- vacantes index -- destroyed')
  }
}
</script>
